@font-face {
  font-family: DIN;
  font-display: swap;
  src: url("./assets/fonts/din_light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: DIN;
  font-display: swap;
  src: url("./assets/fonts/din.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: DIN;
  font-display: swap;
  src: url("./assets/fonts/din_medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: DIN;
  font-display: swap;
  src: url("./assets/fonts/din_bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: DIN, sans-serif;
}

:root {
  --primary-color: #CE181E;
  --secondary-color: #fff;
  --error-color: #f4516c;
  --success-color: #4ccd37;
  --dark-color: #888;
  --light-dark-color: #959ba2;
}
